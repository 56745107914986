<template>
  <v-dialog v-model="dialog" persistent max-width="550px">
    <v-card>
      <v-card-title>
        <span class="headline">{{
          !selectedAssociate.id
            ? $t("member.addAssociate")
            : $t("member.editAssociate")
        }}</span>
        <v-spacer></v-spacer>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                autocomplete="username"
                outlined
                v-model="selectedAssociate.email"
                :label="$t('member.email')"
                :placeholder="$t('member.email')"
                @input="$v.selectedAssociate.email.$touch()"
                @blur="$v.selectedAssociate.email.$touch()"
                :error-messages="emailErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-if="!selectedAssociate.id">
              <v-text-field
                autocomplete="new-password"
                outlined
                type="password"
                v-model="selectedAssociate.password"
                :label="$t('member.password')"
                :placeholder="$t('member.password')"
                @input="$v.selectedAssociate.password.$touch()"
                @blur="$v.selectedAssociate.password.$touch()"
                :error-messages="passwordErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="selectedAssociate.user_detail.representative_name"
                :label="$t('member.representativeName')"
                :placeholder="$t('member.representativeName')"
                @input="
                  $v.selectedAssociate.user_detail.representative_name.$touch()
                "
                @blur="
                  $v.selectedAssociate.user_detail.representative_name.$touch()
                "
                :error-messages="representativeNameErrors"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                maxlength="14"
                v-model="selectedAssociate.user_detail.representative_phone"
                :label="$t('member.representativeCelPhone')"
                :placeholder="$t('member.representativeCelPhone')"
                @input="
                  $v.selectedAssociate.user_detail.representative_phone.$touch()
                "
                @blur="
                  $v.selectedAssociate.user_detail.representative_phone.$touch()
                "
                :error-messages="representativePhoneErrors"
                hide-details="auto"
                outlined
              ></v-text-field>
              <!-- v-to-us-format -->
            </v-col>

            <v-col cols="12">
              <v-select
                outlined
                v-model="selectedAssociate.services"
                :items="selectedMember.services"
                item-text="name"
                item-value="id"
                :label="$t('member.services')"
                :placeholder="$t('member.services')"
                @input="$v.selectedAssociate.services.$touch()"
                @blur="$v.selectedAssociate.services.$touch()"
                :error-messages="servicesErrors"
                multiple
                chips
                hide-details="auto"
                return-value
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-switch
                outlined
                v-model="selectedAssociate.is_active"
                :label="$t('member.isActive')"
                hide-details="auto"
                :placeholder="$t('member.isActive')"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" dark dense @click="close"> Cancel </v-btn>
        <v-btn
          color="primary"
          :loading="saving"
          :disabled="
            ($v.selectedAssociate.$invalid && $v.selectedAssociate.$anyDirty) ||
            saving
          "
          @click="save"
        >
          Save
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import { toSimplePhone } from "../../utils/number.utility";
import {
  required,
  requiredIf,
  email,
  minLength,
} from "vuelidate/lib/validators";
import { showSnackbar } from "../../eventbus/action.js";
export default {
  name: "CreateAndEditAssociate",
  props: {
    dialogStatus: {
      required: true,
      type: Boolean,
      default: false,
    },
    selectedMember: {
      required: false,
      type: Object,
      default: () => {
        return { services: [], user_detail: {} };
      },
    },
    selectedAssociateData: {
      required: false,
      type: Object,
      default: () => {
        return { is_active: false, user_detail: {} };
      },
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.selectedAssociate.email.$dirty) return errors;
      !this.$v.selectedAssociate.email.email &&
        errors.push(this.$t("member.validations.emailIsInvalid"));
      !this.$v.selectedAssociate.email.required &&
        errors.push(this.$t("member.validations.emailIsRequired"));
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.selectedAssociate.password.$dirty) return errors;
      !this.$v.selectedAssociate.password.requiredIf &&
        errors.push(this.$t("member.validations.passwordIsRequired"));
      !this.$v.selectedAssociate.password.minLength &&
        errors.push(this.$t("member.validations.passwordMinLength"));
      return errors;
    },

    representativeNameErrors() {
      const errors = [];
      if (!this.$v.selectedAssociate.user_detail.representative_name.$dirty)
        return errors;
      !this.$v.selectedAssociate.user_detail.representative_name.required &&
        errors.push(this.$t("member.validations.representativeNameIsRequired"));
      return errors;
    },

    representativePhoneErrors() {
      const errors = [];
      if (!this.$v.selectedAssociate.user_detail.representative_phone.$dirty)
        return errors;
      !this.$v.selectedAssociate.user_detail.representative_phone.required &&
        errors.push(
          this.$t("member.validations.representativePhoneIsRequired")
        );
      return errors;
    },

    servicesErrors() {
      const errors = [];
      if (!this.$v.selectedAssociate.services.$dirty) return errors;
      !this.$v.selectedAssociate.services.required &&
        errors.push(this.$t("member.validations.servicesIsRequired"));
      return errors;
    },
  },

  data() {
    return {
      saving: false,
      selectedAssociate: this.selectedAssociateData,
      dialog: this.dialogStatus,
      defaultItem: {
        is_active: false,
        user_detail: {},
      },
    };
  },

  validations: {
    selectedAssociate: {
      email: { required, email },
      password: { requiredIf, minLength: minLength(8) },
      services: { required },
      user_detail: {
        representative_phone: { required },
        representative_name: { required },
      },
    },
  },

  methods: {
    ...mapActions({
      createAssociate: "members/createAssociate",
      updateAssociate: "associates/updateAssociate",
    }),

    close() {
      this.dialog = false;
      this.$emit("close", false);
      this.selectedAssociate = _.cloneDeep(this.defaultItem);
    },

    async save() {
      this.$v.selectedAssociate.$touch();
      if (!this.$v.selectedAssociate.$invalid) {
        try {
          this.saving = true;
          let payload = _.cloneDeep(this.selectedAssociate);
          payload.user_detail.representative_phone = toSimplePhone(
            payload.user_detail.representative_phone
          );
          if (_.some(payload.services, _.isObject)) {
            payload.service_ids = [...payload.services.map((item) => item.id)];
          } else {
            payload.service_ids = [...payload.services];
          }
          if (!payload.id) {
            payload = {
              ...payload,
              parent_id: this.selectedMember.id,
            };
            await this.createAssociate({
              ...payload,
            });
          } else {
            await this.updateAssociate({
              ...payload,
            });
          }
          this.$emit("refresh");
          await this.close();
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
        } finally {
          this.saving = false;
        }
      }
    },
  },
};
</script>
